@import "src/styles/empCssLibs/variables";
@import "node_modules/react-datepicker/src/stylesheets/datepicker";
@import "~react-datepicker/src/stylesheets/datepicker.scss";

.calendarDialogContainer {
  .container {
    padding: 4% 3% 4% 6%;
  }

  .dialogContainer {
    width: 600px;
    height: 100%;
    background: white;
  }

  .displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .monthAndYear {
    font-family: FordAntennaCondRegular, sans-serif;
    color: $blue-zodiac;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .buttonsDiv {
    @extend .displayFlex;
    width: 485px;
    padding-top: 15px;
  }

  .buttonText {
    font-family: FordAntennaCondRegular, sans-serif;
    color: $blue-zodiac;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    padding-left: 5px;
  }

  .setButtons {
    @extend .displayFlex;
  }

  .setButtons:hover {
    cursor: pointer;
  }

  .icon {
    background: url('../../../../imgs/chevron-white-left.svg') no-repeat 3% center;
    width: 18px;
    border: 0;
    height: 18px;
    margin: 5px 12px 0 12px;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: transparent;
    @extend .icon;
  }

  .react-datepicker__month-container {
    border: solid 1px $blue-zodiac;
  }

  .react-datepicker__month {
    margin: 0 12px 15px 12px;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: transparent;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous:focus {
    outline: none;
  }

  .react-datepicker__portal .react-datepicker__navigation--next {
    @extend .icon;
    border-left-color: transparent;
    transform: rotate(180deg);
  }

  .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: transparent;
  }

  .react-datepicker__portal .react-datepicker__navigation--next:focus {
    outline: none;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 0;
    padding-top: 0;
  }

  .react-datepicker__day-name {
    width: 54px !important;
    font-family: FordAntennaCondRegular, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: $blue-zodiac;
    text-transform: uppercase;
    padding-top: 10px;
    line-height: 30px !important;
    letter-spacing: 1.88px;
  }

  .react-datepicker__current-month {
    font-stretch: normal;
    font-style: normal;
    line-height: 3.7;
    letter-spacing: 2.5px;
    font-family: FordAntennaCondMedium, sans-serif;
    color: $white;
    background-color: $blue-zodiac;
    border: solid 1px $blue-zodiac;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px !important;
  }

  .react-datepicker__day {
    width: 54px !important;
    font-family: FordAntennaCondRegular, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1px;
    text-align: center;
    color: $tundora;
    line-height: 25px !important;
    margin-top: 6px;
  }

  .selectedDate {
    color: white;
    background-color: $blue-zodiac;
    border-radius: 3px;
  }

  .react-datepicker__day--selected {
    @extend .selectedDate
  }

  .react-datepicker__day--selected:hover {
    @extend .selectedDate
  }

  .react-datepicker__day--keyboard-selected {
    @extend .selectedDate
  }

  .react-datepicker__day--keyboard-selected:hover {
    @extend .selectedDate
  }

  .react-datepicker__day--outside-month {
    background-color: $background-gray;
    border-radius: 3px;
  }

  .react-datepicker__day--disabled {
    color: $dusty-gray;
    background-color: $background-gray;
    border-radius: 3px;
  }

  .headerContainer {
    display: flex;
    align-items: center;
  }

  .closeIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .calendarContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    margin: 0 72px;
  }

  .dialogTitle {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 2.5px;
    color: $blue-zodiac;
    font-family: FordAntennaCondBold, sans-serif;
    text-transform: uppercase;
    text-align: center;
    padding: 0 15%;
  }

  .dialogTitleLight {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 2.5px;
    color: #092a53;
    font-family: FordAntennaCond, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }


  .dialogDescription {
    font-family: FordAntennaCondMedium, sans-serif;
    color: $tundora;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 1.5px;
    margin: 22px 72px 41px 72px;
  }

  .cancel {
    padding: 10px 5px 0 10px;
    background: transparent;
    justify-content: flex-end;
    display: flex;
  }

  .cancel button {
    border: none;
    background: transparent;
    cursor: pointer;
    font-weight: normal;
  }


  button.applySave {
    width: 385px;
    height: 50px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $button-primary-bg-color;
    font-family: FordAntennaCondMedium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1.5px;
    text-align: center;
    color: white;
  }

  button.applySave:hover {
    background-color: $button-primary-bg-color;
    color: white;
  }


  .calendarContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
}

.applyButtonFormat {
  padding: 40px 0 54px 0;
}

.dialogWrapper {
  display: flex;
  align-items: center;
}

