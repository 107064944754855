@import "../../../styles/empCssLibs/variables";


.flexWithSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.border-bottom {
  border-bottom: solid 0.5px $secondary-border-color;
}

.columnHeaderRow, .dataRow {
  @extend .flexWithSpaceBetween;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dataRow {
  border-bottom: solid 0.5px $section-table-row-border-color;
}

.columnHeaderRow {
  @extend .border-bottom;
}

*::-ms-backdrop, .dataRow {
  padding-top: 8px;
  padding-bottom: 4px;
}

.columnHeader {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  line-height: 3.5;
  letter-spacing: 1.67px;
  color: $primary-text-bold-color;
}

.dataRowCell {
  font-size: 12px;
  line-height: 3.0;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  font-family: FordAntennaRegular, sans-serif;
}

.merchantIdInput > div {
  margin: 2px 0;
  height: 32px;
  font-size: 14px;
}

.merchantIdInput > div fieldset {
  border-radius: 0;
  border: solid 1px $secondary-border-color;
}

.merchantIdSDLR {
  font-size: 14px;
  font-family: FordAntennaCondMed, sans-serif;
  display: flex;
  align-items: center;
}

.merchantIdFooter {
  justify-content: flex-end;
}

.buttonDiv {
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDiv button {
  width: 200px;
  min-height: 48px;
  height: auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.3px;
  text-align: center;
  border: none;
  cursor: pointer;
}

.buttonDiv button:hover {
  background: $button-primary-bg-color;
}

.buttonDiv button:focus {
  outline: none;
  border: none;
}

.disabledButtonDiv {
  @extend .buttonDiv
}

.disabledButtonDiv button {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.disabledButtonDiv button:hover {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.extraPaddingRight {
  text-align: left;
}

.merchantIdText {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  font-family: FordAntennaRegular, sans-serif;;
}

.errorMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 30px 0;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  color: $errorRed;
}