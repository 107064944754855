@import '../../../styles/empCssLibs/variables';

.tableBody td {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
}

.tableHeader th{
  border-bottom-color: $secondary-border-color;
}

.download {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  color: $button-primary-bg-color;
}

.pointer {
  cursor: pointer;
}

.download span {
  padding: 3px;
}

.alignCenter {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.alignStart {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.title h2 {
  font-family: FordAntennaCond-Light, sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: 2.75px;
  color: $secondary-text-color;
}

.deleteWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteWrapper :hover {
  cursor: pointer;
}

.downloadButton {
  display: flex;
  align-items: center;
}
