@import "../../styles/empCssLibs/variables";

.subNav {
  text-align: center;
}

.subNav div button span {
  font-size: 14px;
  font-family: FordAntennaCondMedium, sans-serif;
}

.container {
  height: 110px;
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.container p {
  margin: 0;
}

.titleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}

.root {
  height: 50px;
  background: linear-gradient(to bottom, #164069, $primary-text-bold-color);
  color: $primary-bg-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding-left: 6%;
  padding-right: 6%;
}

.root .flexContainer{
  height: 50px;
}

.welcome {
  font-family: FordAntennaBold, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.74px;
  color: $primary-text-color;
}

.dropdownDiv {
  margin: 0 30px;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dealerName {
  padding: 5px 10px;
  margin: 0;
}

.unonboardedDealer {
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
}

.unonboardedDealerIcon {
  padding-right: 10px;
}

.subNav .moreTab span {
  font-size: 14px;
  font-family: FordAntennaCondMedium, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.subNav .moreTab {
  box-sizing: border-box;
  padding: 10px 42px 10px 24px;
  min-width: 109px;
  cursor: pointer;
}

#subNavMenuDropdown div li {
  width: 300px;
}

.subNav .root .scrollable {
  height: 67px;
}
