@import "../../../../styles/empCssLibs/variables";


.notificationBox {
  background-color: $warning-bg-color-pink;
  width: 768px;
  height: 40px;
}

.actionNotificationBox {
  height: 40px;
  display: flex;
  align-items: center;
  font-family: FordAntenna, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  padding: 0 30px;
  border-bottom: 1px solid $medium-gray;
}

.warningBoxColor {
  background-color: $warning-bg-color-pink;
}

.whiteBoxColor {
  background-color: $primary-bg-color;
}

.actionNotificationText {
  width: 25%;
  display: inline-flex;
  align-items: center;
  font-family: FordAntennaCond, sans-serif;
  font-weight: 600;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
}

.actionNotificationMsg {
  width: 45%;
}

.actionWarningIcon {
  height: 32px;
  width: 32px;
  background-repeat: no-repeat;
  background-image: url('../../../../imgs/warning-blue.svg');
  margin-right: 10px;
}

.closeDiv {
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  height: 32px;
  width: 32px;
  background-repeat: no-repeat;
  background-image: url('../../../../imgs/delete.svg');
  margin-right: 10px;
  cursor: pointer;
}