@import '../../../styles/empCssLibs/variables.scss';
@import "../../../styles/common/CommonStyles.module.scss";

.backToOrderText {
  font-family: FordAntennaCondMedium, sans-serif;
  color: $primary-text-bold-color;
  font-weight: 800;
  letter-spacing: 2.17px;
  font-size: 12px;
  margin: 0 10px;
}

.orderNumber {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
}

.orderNumberHeader {
  width: 90%;
  margin: auto;
  padding-top: 15px;
  position: relative;
}

.back {
  margin: 30px 0 20px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  text-decoration: none;
}

.componentContainer {
  width: 90%;
  margin: 25px auto;
  box-sizing: border-box;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.buttonDiv {
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDiv button {
  width: 300px;
  min-height: 48px;
  height: auto;
  border-radius: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $primary-bg-color;
  color: $primary-text-bold-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.3px;
  text-align: center;
  border: none;
  cursor: pointer;
}

.buttonDiv button:focus {
  outline: none;
  border: none;
}

.headerBar {
  width: 100%;
  font-size: 14px;
  font-family: FordAntennaCondMedium, sans-serif;
  letter-spacing: 2.25px;
  padding: 0 4%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-bold-color;
}

.nestedHeaderBar {
  @extend .headerBar;
  padding: 0 0 0 3%;
}

.header {
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  line-height: 2.5;
  text-transform: uppercase;
  color: $primary-text-bold-color;
}

.boldHeader {
  font-size: 16px;
  font-family: FordAntennaCondBold, sans-serif;
  letter-spacing: 2.25px;
  color: $primary-text-bold-color;
  text-transform: uppercase;
}