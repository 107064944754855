@import '../../../styles/empCssLibs/variables';

.divider {
  padding: 0 6% 2px 6%;
  border-bottom: 3px solid $primary-text-bold-color;
}

.checkboxHeader {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.checkDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkbox {
  margin: 0 10px;
}

.warning {
  height: 27px;
  width: 30px;
  background-repeat: no-repeat;
  background-image: url('../../../imgs/warning.svg');
  margin-right: 10px;
}

.saved {
  height: 27px;
  width: 27px;
  background-repeat: no-repeat;
  background-image: url('../../../imgs/success-state.png');
  margin-right: 10px;
}

.descriptionHeader {
  display: flex;
  align-items: flex-end;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color
}

.tooltipContainer {
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.editHeader {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
  margin: 0 5px;
}

.negativeMarginTitle {
  margin-left: 5px;
  margin-right: -8px;
}

.title {
  margin-left: 5px;
}

.editIcon {
  margin-bottom: 1px;
  margin-right: 5px;
}

.countDiv {
  padding: 5px 6%;
  border-bottom: 0.5px solid $dusty-gray;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
}

.count {
  font-family: FordAntennaCond-Bold, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;

}

.row {
  padding: 5px 6%;
  border-bottom: 0.5px solid $dusty-gray;
}

.redRow {
  @extend .row;
  background-color: $faded-red;
}

.descriptionRow {
  display: flex;
  align-items: center;
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: $primary-text-bold-color
}

.editRow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: $tundora;
}

.leadTimesInput {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.leadTimesInput input {
  box-sizing: border-box;
  width: 70px;
  box-shadow: inset 2px 2px 2px 0 rgba(0, 0, 0, 0.27);
  border: solid 1px #4a4a4a;
  background-color: white;
  border-radius: 0;
  padding: 5px;
  text-align: center;
  height: 25px;
}

.leadTimesInput fieldset {
  width: 70px;
  box-sizing: border-box;
  border-radius: 0;
}

.leadTimesInput input::placeholder {
  text-align: center;
}

.saveSuccess {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  color: $accept-green;
}

.buttonDiv {
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDiv button {
  width: 300px;
  height: 50px;
  background-image: linear-gradient(to bottom, $light-green, $dark-green);
  border: none;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.buttonDiv button:disabled {
  color: $dusty-gray;
  background-image: none;
  background-color: $gray90;
  cursor: auto;
}

.tooltipWrapper {
  padding: 30px 30px 20px 30px;
}

.tooltipTitle {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
}

.tooltipBody {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
}

.tooltipDismiss {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.25px;
  text-align: center;
  color: $primary-text-bold-color;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.tooltipDismissContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
