@import "../../../../styles/empCssLibs/variables";
@import "../../../../styles/empCssLibs/main";

.root {
  flexGrow: 1;
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 20px;
}

/*Utility classes for this component*/
.businessSettings {
  font-family: FordAntennaCondExtraLight, sans-serif;
  font-size: 40px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: 5px;
  color: $primary-text-bold-color;
}

.divider {
  border-bottom: 2px solid grey;
  text-align: left;
}

.rowHeader {
  font-family: FordAntennaCondMed, sans-serif;
  padding: 5px 0px;
  color: #4a4a4a;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.headerWrap {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionSection {
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 1440px;
  margin: 60px 0;
}

button.btn {
  width: 250px;
  height: 80px;
  background-color: $primary-text-bold-color;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 4px;
  text-align: center;
  color: #ffffff;
  margin-right: 25px;
}

button.btn:hover {
  background-color: $primary-text-bold-color;
}

button.add {
  text-transform: none;
  display: block;
  color: $soft-blue;
  border-radius: 6px;
  background-color: white;
  font-family: FordAntenna-Regular, sans-serif;
  margin: 15px 0 15px 6%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 2.6;
  letter-spacing: 1px;
}

button.saveBtn {
  display: block;
  height: 50px;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  padding: 10px 25px;
  text-align: center;
  color: $white;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #00ad46, #00913b);
  width: 302px;
  letter-spacing: 1.5px;
}

.max {
  color: $alizarin-crimson;
  font-size: 13px;
  font-family: FordAntenna-Regular, sans-serif;
  letter-spacing: 0.79px;
  @extend .pagePaddingLeft;
}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.pricesApplied {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  letter-spacing: 1px;
  font-family: FordAntennaCondMed, sans-serif;
  color: #1d9d01;
  font-size: 20px;
  margin-top: 4%;
}