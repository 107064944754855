@import '../../../../styles/empCssLibs/variables.scss';


.progressTrackerWrapper {
  background: white;
}

.progressTrackerHeader {
  font-size: 12px;
  font-family: FordAntennaCondMedium, sans-serif;
  letter-spacing: 3px;
  height: 30px;
  display: flex;
  justify-content: center;
  padding: 25px 0;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  text-align: center;
  color: $bold-gray;
}

.progressBar {
  width: 45%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 90px;
}

.progressBarCancelled {
  @extend .progressBar;
  width: 25%;
}

.navTab, .navStepButton {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.connectingLine {
  top: 12px;
  left: calc(-50% + 10px);
  right: calc(50% + 10px);
  position: absolute;
  flex: 1 1 auto;
  width: auto;
}

.connectingLine span {
  border-top-style: solid;
  border-top-width: 2px;
  display: block;
  border-color: $pale-gray;

  &.active {
    text-transform: uppercase;
    border-color: $primary-text-bold-color;
  }
}

.navStepButton {
  flex-direction: column;
}

.progressTabNumber {
  width: 24px;
  height: 24px;
  border: 1px solid $primary-bg-color;
  border-radius: 25px;
  position: relative;
  color: $primary-text-bold-color;
  background-color: $gray90;
  z-index: 9;

  &.active {
    text-transform: uppercase;
    background-color: $primary-text-bold-color;
  }

  &.visited {
    background-color: $medium-gray;
  }
}

.stepNumber {
  position: absolute;
  left: 9px;
  top: 4px;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 2px;
  color: $dark-grey;

  &.active {
    text-transform: uppercase;
    color: white;
  }

  &.visited {
    color: $primary-text-bold-color;
  }
}

.progressTab {
  margin: 9px 10px 0 10px;
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 2px;
  text-align: center;
  color: $dark-grey;
  width: 80px;

  &.active {
    text-transform: uppercase;
    font-family: FordAntennaCondMedium, sans-serif;
    font-weight: 500;
    color: $primary-text-bold-color;
  }

  &.visited {
    color: $primary-text-bold-color;
  }
}

.subheaderDiv {
  padding: 10px 0 25px 0;
}

.subheaderText {
  width: 100%;
  margin: auto;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: 1px;
  text-align: center;
  color: $primary-text-bold-color;
}

.subheaderTextBold {
  @extend .subheaderText;
  font-weight: bold;
}
