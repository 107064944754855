@import "../../../styles/empCssLibs/variables";

.pageHeader {
  padding: 25px 0 15px;
  color: $tundora-light;
  font-family: FordAntennaCond, sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.subHeader {
  padding: 10px 0 15px;
  color: $tundora;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
}

.errorMessage {
  @extend .subHeader;
  padding: 0;
  color: $alizarin-crimson;
}

.buttonDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0 10px;
  width: 100%;
  box-sizing: border-box;
}

.buttonDiv button {
  width: 297px;
  min-height: 40px;
  height: auto;
  background-color: $primary-bg-color;
  color: $primary-text-bold-color;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  text-align: left;
  border-radius: 20px;
  border: solid 1px $primary-text-bold-color;
}

.buttonText {
  margin: 0 7.5px;
}

.updateCompleteText {
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 3px;
  color: $light-green;
  margin: 0 15px;
}

.gridHeader {
  color: $deep-blue;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.2;
  letter-spacing: 1.88px;
}

.headerRow {
  padding-left: 6%;
  border-bottom: 3px solid $primary-text-bold-color;
  align-items: flex-end;
}

.gridHeaderCentered {
  @extend .gridHeader;
  text-align: center;
}

.gridHeaderRight {
  @extend .gridHeader;
  text-align: right;
}

.gridRow {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.dealerName {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}

.greyText {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: $white-gray;
}

.borderBottom {
  border-bottom: solid 0.5px $dusty-gray;
}

.gridRowCentered {
  @extend .gridRow;
  font-size: 11px;
  text-align: center;
  justify-content: center;
}

.gridRowRight {
  @extend .gridRow;
  font-size: 11px;
  text-align: right;
  justify-content: flex-end;
}

.updateLink {
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.25px;
  color: $bright-blue;
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.downloadDiv {
}

.download {
  @extend .pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $blue-zodiac;
}

.download img {
  padding-left: 5px;
  padding-right: 10px;
}

.uploadFlexBox {
  display: flex;
  justify-content: center;
}

.uploadFlexBox img {
  padding-left: 5px;
}

.updateText {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.25px;
  color: $primary-text-bold-color;
  text-align: right;
}

.applyButtonDiv, .closeButtonDiv {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.applyButtonDiv button {
  width: 302px;
  height: 48px;
  border-radius: 3px;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 1.5px;
  text-align: center;
  color: white;
  cursor: pointer;
  background-image: linear-gradient(to bottom, $light-green, $dark-green);
  border: none;
}

.closeButtonDiv button {
  width: 302px;
  height: 48px;
  border-radius: 3px;
  background-color: $pale-gray;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 1.5px;
  text-align: center;
  color: white;
  cursor: pointer;
  border: none;
}

.saveSuccess {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 3px;
  text-align: center;
  color: $light-green;
}

.saveError {
  margin: 10px 6%;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.94px;
  color: $warning-text-color;
}

.inputBox {
  width: 100%;
}

.inputBox fieldset {
  border-radius: 0;
}

.inputBox input {
  border-radius: 0;
  padding: 2.5px 10px;
  font-size: 12px;
  font-family: FordAntennaCondMed, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  text-align: right;
  box-shadow: inset 2px 2px 2px 0 rgba(74, 74, 74, 0.5);
  border: solid 1px #4a4a4a;
  background-color: white;
  height: 25px;
}

.updateCompleted {
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: 2px;
  text-align: center;
  color: $light-green;
  margin-left: 12px;
}

.emailHeader {
  display: flex;
  align-items: flex-end;
}