@import '../../../../styles/empCssLibs/variables';

.dialogContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 0 0;
}

.gridContainer {
  justify-content: center;
  flex-direction: column;
  padding: 2% 0;
}

.dialogTitle {
  font-family: FordAntennaCond-Bold, sans-serif;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 2.5px;
  color: $blue-zodiac;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.dialogDescription {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 1.5px;
  color: $tundora;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  margin: 15px 0;
  font-weight: normal;
}

.inputLabel {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $blue-zodiac;
  padding: 7px 0;
}

.closeBtn {
  padding: 10px 5px 0px 10px;
  background: transparent;
  justify-content: flex-end;
  display: flex;
}

.closeBtn button {
  border: none;
  background: transparent;
  cursor: pointer;
}

button.backBtn {
  width: 48%;
  height: 50px;
  box-shadow: 0 1px 4px 0 rgba(74, 74, 74, 0.5);
  background-color: white;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
  color: $primary-text-bold-color;
}

button.backBtn:hover {
  background: white;
}

button.confirmBtn {
  width: 48%;
  height: 50px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $button-primary-bg-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
  color: white;
}

button.confirmBtn:hover {
  background-color: $button-primary-bg-color;
  color: white;
}

button.confirmBtn:disabled {
  color: $dusty-gray;
  box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.5);
  background-color: $gray90;
}

.valuationAmountInput {
  padding: 0 40px;
}

.textBox input {
  width: 300px;
  height: 30px;
  box-shadow: inset 2px 2px 2px 0 rgba(74, 74, 74, 0.25);
  border: solid 1px $primary-text-color;
  background-color: white;
  text-align: left;
  padding: 5px 7px;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  color: $primary-text-color;
}

.inputField {
  margin: 10px 0 10px 10px;
  padding: 5px;
  width: 230px;
  height: 30px;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.35);
  background-color: #f6f6f6;
}

.cancel {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.calendar {
  position: relative;
  margin-bottom: 20px;
}

.calendar input {
  height: 30px;
  width: 300px;
  box-shadow: inset 2px 2px 2px 0 rgba(74, 74, 74, 0.25);
  border: solid 1px $primary-text-color;
  background-color: white;
  text-align: left;
  padding: 5px 7px;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  color: $primary-text-color;
}

.dialogWrapper {
  padding: 40px;
}
