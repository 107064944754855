@import '../../styles/empCssLibs/variables.scss';
@import "../../styles/common/CommonStyles.module.scss";

.tablePadding {
  padding-top: 10px;
}

.headerBar {
  width: 100%;
  font-size: 14px;
  font-family: FordAntennaCondMedium, sans-serif;
  letter-spacing: 2.25px;
  padding: 0 4%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-bold-color;
}

.bottomBorder {
  border-bottom: 1px solid #102b4e;
}

.tableBody td {
  font-family: FordAntennaCond, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
}

.tableLink {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  color: $primary-text-bold-color;
  text-decoration: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.alignCenter {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
}

.alignStart {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
}

.orderTableHeaderRow th {
  border-bottom-color: $secondary-border-color;
}

.id {
  font-weight: normal;
}

.pre {
  white-space: pre;
}

.sortUpFilled {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/sort_up_filled.svg');
  display: block;
  margin: 0 3px;
}

.sortDown {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/sort_down.svg');
  display: block;
  margin: 0 3px;
}

.sortUp {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/sort_up.svg');
  display: block;
  margin: 0 3px;
}

.sortDownFilled {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/sort_down_filled.svg');
  display: block;
  margin: 0 3px;
}

.pagesDiv {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pages {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 1.14px;
  color: $secondary-text-color;
  margin: 0 30px;
}

.pagesDiv button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 3px;
  padding: 0;
}

.pagesDiv button:focus {
  outline: none;
}

.pagesDiv button:hover {
  cursor: pointer;
}

.pagesDiv button:disabled:hover {
  cursor: default;
}

.expandIcon {
  display: flex;
  justify-content: flex-end;
}

.searchIcon {
  width: 32px;
  height: 32px;
  background-image: url("../../imgs/search-dark-blue.svg");
}

.searchIcon:hover {
  cursor: pointer;
}

.searchButton {
  background: transparent;
}

.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2%;
}

.form input::placeholder {
  font-size: 13px;
  font-family: FordAntennaCondMed, sans-serif;
}

.form input[type="text"] {
  font-size: 13px;
  font-family: FordAntennaCondMed, sans-serif;
  letter-spacing: 0.71px;
  color: $primary-text-bold-color;
  padding: 5px 5px 5px 7px;
  text-align: left;
  z-index: 1;
  height: 100%;
}

.inputField {
  width: 350px;
  height: 30px;
}

.searchWrapper button {
  border: none;
}

.searchWrapper button:focus {
  outline: none;
}

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $button-primary-bg-color;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 1.75px;
}

.clear {
  background: transparent;
  font-size: 15px;
  font-family: FordAntennaCondMed, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.71px;
  color: $primary-text-bold-color;
  padding-left: 10px;
}

.clear:hover {
  cursor: pointer;
}

.specsContainer {
  line-height: 1.71;
}

.nameplate {
  font-size: 14px;
  margin: 5px 0;
}

.specs {
  font-size: 12px;
}

.noDataFoundMsg {
  padding: 0% 6% 0% 6%;
}

.convertLink {
  font-family: FordAntennaCondMedium;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.88px;
  text-align: center;
  padding-top: 3px;
  color: $blue-zodiac;
}

.convertDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}