@import "../../styles/empCssLibs/variables.scss";

.divider {
  border-bottom: 2px solid grey;
  padding: 0 6%;
}

.rowDivider {
  border-bottom: 1px solid $dusty-gray;
  padding: 0 6%;
}

.leftAlignment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rightAlignment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.header {
  height: 100%;
  align-items: flex-end;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
  padding: 5px 0;
  box-sizing: border-box;
}

.icon {
  cursor: pointer;
}

.aldCupidId {
  height: 100%;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
  padding: 5px 0;
  box-sizing: border-box;
}

.textBoxCell {
  padding: 5px 0;
  box-sizing: border-box;
}

.textBoxCell input {
  width: 100%;
  height: 20px;
  box-shadow: inset 2px 2px 2px 0 rgba(74, 74, 74, 0.5);
  border: solid 1px $tundora;
  background-color: white;
  padding: 5px;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: right;
  color: $primary-text-bold-color;
  border-radius: 0;
}

.textBoxCell fieldset {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0;
}

.inputField {
  align-items: flex-end;
}

.inputField div {
  width: 150px;
}

.inputField p {
  text-align: right;
  margin: 5px 0 0 0;
}

.cupidIdValue {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: right;
  color: $tundora;
}

.saved {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 30px 0;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  color: $faux-green;
}

.applyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 30px 0;
}

.applyButton button {
  width: 300px;
  height: 50px;
  border-radius: 0;
  background-image: linear-gradient(to bottom, $light-green, $dark-green);
  border: none;
  cursor: pointer;
  color: white;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
}

.applyButton button:disabled {
  background-image: none;
  background-color: $gray90;
  color: $dusty-gray;
}