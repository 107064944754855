@import "../../styles/empCssLibs/variables";

.subHeader {
  padding: 16px 0 18px;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
}

.bodyContent {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  margin-bottom: 15px;
}

.tradeInWrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 4%;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  opacity: 0.8;
  z-index: 2;
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.3s ease - in -out;
  -moz-transition: all 0.3s ease - in -out;
  -o-transition: all 0.3s ease - in -out;
  -ms-transition: all 0.3s ease - in -out;
  transition: all 0.3s ease - in -out;
}

.acceptRejectDiv {
  display: flex;
  align-items: center;
}

.acceptRejectButton {
  border: none;
  background: transparent;
  cursor: pointer;
  color: $primary-text-bold-color;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.25px;
  text-decoration: underline;
}

.acceptRejectButton:focus {
  outline: none;
  border: none;
}

.disabledAcceptRejectButton {
  color: $dark-grey;
  cursor: auto;
  border: none;
  background: transparent;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.25px;
  text-decoration: underline;
}

.exitButton {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.header {
  color: $primary-text-bold-color;
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  line-height: 2.5;
  text-transform: uppercase;
}

.disableDealerActionsAccept {
  color: $dark-grey;
  cursor: auto;
  background-color: transparent;
}