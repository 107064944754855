@import '../../styles/empCssLibs/variables';

.previewTitle {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 2.85px;
  color: $primary-text-bold-color;
}

.switches {
  text-align: right;
  color: $secondary-text-color;
  font-size: 14px;
  letter-spacing: 2.25px;
  font-family: FordAntennaRegular, sans-serif;
}

.dealerName {
  color: $secondary-text-color;
  font-size: 9pt;
  letter-spacing: 2.25px;
  font-family: FordAntennaRegular, sans-serif;
}

div.previewPDF {
  height: 500px;
  overflow-y: scroll;
  margin: 25px 0;
  border: solid 1px $secondary-border-color;
  background-color: $primary-bg-color;
}

.download {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 0.86px;
  color: $primary-text-bold-color;
}

.downloadPointer {
  cursor: pointer;
  text-decoration: underline;
}

.acceptance {
  display: flex;

  span:nth-child(2) {
    padding-left: 10px;
  }
}

.downloadFile {
  display: flex;
  justify-content: space-between;

  span {
    color: $primary-text-bold-color;
  }
}

.subNav {
  text-align: center;
}

.subNav div button span {
  font-size: 18px;
  font-family: FordAntennaCondMedium;
}


.toggleWrapperMain {
  float: right;
}

.toggleWrapper {
  @extend .toggleWrapperMain;
  width: 130px !important;
  height: 40px !important;
  margin: 0 !important;
  box-shadow: 0 1px 0 0 $primary-bg-color, 0 0 0 0 $primary-bg-color, 0 0 0 1px $primary-bg-color, 0 0 0 0 rgba(0, 0, 0, 0.2), inset 0 2px 5px 0 rgba(0, 0, 0, 0.35), inset 0 -2px 5px 0 $primary-bg-color !important;
  background-color: $primary-bg-color;
}

.membershipInfo {
  padding: 0 6%;
  margin-top: 20px;
}

.membershipTitle {
  padding: 0 6%;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: FordAntennaCond-Light, sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: 2.75px;
  color: $secondary-text-color;
}

.activationPanel {
  color: $secondary-text-color;
  padding: 20px 6%;
  align-items: center;
  font-size: 12pt;
  letter-spacing: 2.25px;
  border-bottom: solid 1px $section-table-row-border-color;

  .toggleLabel {

  }

  &:nth-child(1) {
    border-top: 1px solid $secondary-border-color;
  }

}