@import "../../styles/empCssLibs/variables";

.dialogBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dialogPadding {
  padding: 0 16%;
}

.cancelButton {
  display: flex;
  justify-content: flex-end;
  padding: 23px 23px 0 0;
}

.closeDialogBtn {
  height: 100%;
  border: none;
  background: transparent;
  top: 0;
  right: 5%;
  cursor: pointer;
}

.dialogHeader {
  @extend .dialogBody;
  align-items: center;
  font-family: FordAntennaCond-Bold, sans-serif;
  color: $blue-zodiac;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2px;
}

span.uploadBtn {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  text-align: center;
  color: $primary-text-bold-color;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: $primary-bg-color;
  width: 200px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px $primary-text-bold-color;
  text-transform: none;
}

span.uploadBtn:hover {
  background-color: $primary-bg-color;
}

.dialogDescription {
  padding: 14px 16% 33px 17%;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
}

.fileTypeDesc {
  @extend .dialogPadding;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  font-family: FordAntenna-Regular, sans-serif;
  color: $tundora-light;
  padding-top: 7px;
}

.warningMessage {
  @extend .fileTypeDesc;
  color: $alizarin-crimson;
}


.actionBtnDiv {
  padding: 42px 16% 50px 16%;
  display: flex;
}

.applyBtnDiv {
  padding: 62px 16% 50px 16%;
  display: flex;
  justify-content: center;
}

.applyAllBtnDiv {
  padding: 5px 16% 45px 16%;
  display: flex;
  justify-content: center;
}

.applyBranchBtnDiv {
  padding: 5px 16% 5px 16%;
  display: flex;
  justify-content: center;
}

.cancelBtnDiv {
  padding: 40px 16% 5px 16%;
  display: flex;
  justify-content: center;
}


button.actionBtn {
  width: 90%;
  height: 58px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $blue-zodiac, $blue-zodiac);
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

button.closeBtn {
  @extend button, .actionBtn;
  background-image: linear-gradient(to bottom, $white, $white);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3px;
  color: $blue-zodiac;

}

button.disableActionBtn {
  width: 80%;
  margin: auto;
  height: 50px;
  background-color: $gray90;
  border-radius: 0;
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.closeText {
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 2.08px;
  text-align: center;
  color: $dusty-gray;
}

.fileNameTextBox {
  width: 85%;
  min-width: 300px;
  height: 41px;
  border: solid 1px $tundora;
  border-radius: 3px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fileName {
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2;
  letter-spacing: 1.67px;
  color: $blue-zodiac;
}

.fileNameTextBox div {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: 2px;
}

.fileNameTextBox img {
  padding: 15px;
}

.clearImage {
  margin: 0 7.5px;
  cursor: pointer;
}

.dialogSubHeader {
  padding-top: 35px;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  margin: 5px 0;
  color: $tundora;
}

.dialogBox {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid $secondary-border-color;
}

.dialogDealerName {
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
  padding: 5px 0;
  text-align: center;
}