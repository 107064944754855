@import "../../../styles/empCssLibs/variables";

.search {
  font-family: FordAntennaCondExtraLight, sans-serif;
  font-size: 40px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: 5px;
  color: $primary-text-bold-color;
  padding-left: 6%;
}

.searchType {
  height: 247px;
  width: 270px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  object-fit: contain;
  cursor: pointer;
  text-align: center;
  background: #fff;
  color: $primary-bg-color;
  text-decoration: none;
  margin-bottom: 40px;
}

.searchTitle {
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 20px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2.5px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 0 20px 0;
  background-color: #102b4e;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.searchText {
  padding: 20px 10px 20px 10px;
  font-family: FordAntenna,sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.75px;
  color: #102b4e;
  text-align: center;
}

.goto {
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: ultra-expanded;
  font-style: normal;
  line-height: 0.72;
  letter-spacing: 1px;
  color: #102b4e;
  text-align: center;
  padding-top: 20px;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

.rightChevron {
  font-size: 18px !important;
  color: #fff;
  background-color: #102b4e;
  margin-bottom: -3px;
  margin-left: 15px;
  border-radius: 20px;
}

.subHeader {
  text-align: left;
  font-size: 12px;
  color: $tundora-light;
}

.subTitle {
  padding: 10px 0 5px 0;
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 12px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
  font-family: FordAntennaCondMedium, sans-serif;
}

.subNav {
  text-align: center;
}

.titleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}
