@import '../../styles/empCssLibs/variables';

.lanHeader {
  float: left;
  font-family: FordAntennaCondBold, serif;
  font-size: 10px;
  font-weight: bold;
  color: rgb(16, 43, 78);
  padding-top: 10px;
  width: 14%;
  text-align: left;
  letter-spacing: 1.88px;
}

.lanDetails {
  font-family: FordAntennaCond, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  max-width: 100% !important;
  line-height: 2.67;
  letter-spacing: .86px;
  color: #4d4d4d;
}

.lanUpdate {
  float: left;
  width: 20%;
  padding: 10px;
  display: inline-block;
  box-sizing: border-box;
}

.button_hover:hover {
  cursor: pointer;
}

.translation_header {
  padding: 0 6%;
  display: block;
  color: #000066;
  font-weight: bold;
}

.moduleKey {
  width: 100%;
  line-height: 2.5;
  padding: 0 6%;
  display: flex;
  align-items: center;
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 12px;
  letter-spacing: .5px;
  font-weight: 700;
  text-transform: uppercase;
}

.lan {
  padding: 0 6%;
  //display: block;
}

.inputField {
  width: 95%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 10px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  font-family: FordAntennaCondMed, sans-serif;
}

.moduleKeyInput {
  @extend .inputField;
  padding-left: 10px;
}


.inputFieldspecific {
  width: 95%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 10px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  font-family: FordAntennaCondMed, sans-serif;
}

.inputFieldspecific div {
  padding: 5px;
  font-size: 12px;
}

input[type="text"] {
  font-size: 10px;
  padding: 5px;
  font-family: FordAntennaCondMed, sans-serif;
}

.Edit {
  position: relative;
  width: 18px;
  height: 18px;
  display: inline-block;
  padding-left: 12px;
  cursor: pointer;
}

.RectangleStart {
  width: 5px;
  height: 5px;
  transform: rotate(-180deg);
  background-color: $button-primary-bg-color;
  position: absolute;
  left: 7px;
  bottom: 0;
  cursor: pointer;
}

.RectangleEnd {
  position: absolute;
  width: 6px;
  height: 15.8px;
  transform: rotate(-315deg);
  border: solid 1px $primary-text-bold-color;
  background-color: $primary-bg-color;
  cursor: pointer;
}

.newTranslation {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  text-decoration: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 15px;
}

.newTranslation:hover {
  cursor: pointer;
  text-decoration: none;
}
.inputLabel {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $blue-zodiac;
  padding: 7px 20px 7px 0;
}

.dropdown {
  height: 35px;
  box-sizing: border-box;
  text-align: left;
  border: solid 1px $secondary-text-color;
  background-color: white;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}
.dropdownDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.errorText {
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.94;
  letter-spacing: 2.25px;
  color: $button-primary-bg-color;
  padding-top: 36px;
  padding-left: 6%;
  padding-right: 6%;
  box-sizing: border-box;
  text-transform: uppercase;

  h2 {
    font-size: 20px;
    font-family: FordAntennaCondMedium, sans-serif;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: $primary-text-bold-color;;
  }
}