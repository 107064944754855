@import "../styles/empCssLibs/variables";

.errorDialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorDialogContainer {
  width: 100%;
  padding: 5px 10px 20px 5px;
}

.errorDialogClose {
  padding: 10px 5px 0px 10px;
  background: transparent;
  justify-content: flex-end;
  display: flex;
}

.errorDialogClose button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.errorDialogTitle {
  font-family: FordAntennaCond-Bold, sans-serif;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 2.5px;
  color: $bright-red;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.errorDialogDescription {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 1.5px;
  color: $tundora;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  margin: 15px 0;
  font-weight: normal;
}

.errorDialogWarning {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url('../imgs/warning.svg');
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.brandLogo {
  color: $primary-text-bold-color;
  height: 75px;
  margin-top: 16vh;
}

.deniedInfo {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
  text-align: left;
  padding: 0 15%;
}

.deniedTitle {
  font-family: FordAntennaBold, sans-serif;
  font-size: 24px;
  color: $primary-text-bold-color;
  line-height: 1.75;
  letter-spacing: 1px;
  text-align: center;
  margin: 20px 0;
}

.button{
  width: 200px;
  height: 50px;
  box-shadow: 0 1px 4px 0 rgba(74, 74, 74, 0.5);
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
  margin: 25px;
}

button.edit {
  @extend .button;

  background-color: $white;
  color: $primary-text-bold-color;
}

button.edit:hover {
  background: $white;
}

button.confirm {
  @extend .button;

  background-color: $primary-text-bold-color;
  color: $white;
}

button.confirm:hover {
  background-color: $primary-text-bold-color;
}