@import '../empCssLibs/variables.scss';

.subHeader {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
}

button.greenBtn {
  width: 340px;
  height: 48px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $light-green, $dark-green);
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

button.greenBtn:disabled {
  @extend .greenBtn;
  background-image: linear-gradient(to bottom, darkgray, darkgray);
  color: dimgrey;
}

button.cancelledDialogButton, button.cancelledDialogButton:hover {
  height: 58px;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $primary-bg-color;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.63;
  letter-spacing: 3px;
  text-align: center;
  color: $primary-text-bold-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
}

button.confirmDialogButton, button.confirmDialogButton:hover {
  background-color: $button-primary-bg-color;
  color: $primary-bg-color;
  width: 100%;
  height: 58px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.63;
  letter-spacing: 3px;
  text-align: center;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
}

button.confirmDialogButton:disabled {
  background-color: $gray90;
  color: $dusty-gray;
}

button.actionBtn {
  width: 90%;
  height: 58px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $blue-zodiac, $blue-zodiac);
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

button.closeBtn {
  @extend button, .actionBtn;
  background-image: linear-gradient(to bottom, $white, $white);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3px;
  color: $blue-zodiac;
}

.pageTitle {
  font-family: FordAntennaCondExtraLight, sans-serif;
  font-size: 40px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: 5px;
  color: $primary-text-color;
}

// for devices up to 900px
@media only screen and (max-width: $md) {
  .pageTitle {
    font-family: FordAntennaCondLight, sans-serif;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.59;
    letter-spacing: 2.75px;
    color: $primary-text-bold-color;
  }
}

// for device up to 600px
@media only screen and (max-width: $sm) {
  .pageTitle {
    font-family: FordAntennaCondMed, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 2.42px;
    text-align: center;
    color: $primary-text-bold-color;
  }
}

.errorText {
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.94;
  letter-spacing: 2.25px;
  color: $button-primary-bg-color;
  padding-top: 36px;
  padding-left: 6%;
  padding-right: 6%;
  box-sizing: border-box;
  text-transform: uppercase;

  h2 {
    font-size: 20px;
    font-family: FordAntennaCondMedium, sans-serif;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: $primary-text-bold-color;;
  }
}