@import "../../../../styles/empCssLibs/variables";

.label + div, .defaultText {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  line-height: 2.08;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: $secondary-text-color;
}

.label {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.08;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
}

.labelError {
  @extend .label;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.customerInfoGrid {
  padding: 0 4%;
}

.bottomBorder {
  border-bottom: 1px solid $primary-text-bold-color;

}

.customerContainer {
  padding: 25px 0;
}

.infoHeader {
  padding-bottom: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: FordAntennaCondBold, sans-serif;
  letter-spacing: 1px;
  align-items: flex-start;
  display: flex;
  color: $primary-text-bold-color;
}

.infoDetails {
  font-size: 12px;
  font-family: FordAntenna-Regular, sans-serif;
  letter-spacing: 1.5px;
  align-items: flex-start;
  display: flex;
  padding-bottom: 10px;
  color: $primary-text-color;
}

.infoEmail {
  @extend .infoDetails;
  font-weight: bold;
  color: $primary-link-color

}

.expansionBox {
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.itemHeaderText {
  font-family: FordAntennaCond-Light, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.08;
  letter-spacing: 1px;
  color: $secondary-text-color;
}

.itemValueText {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.08;
  letter-spacing: 2px;
  color: $secondary-text-color;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.cancelDiv {
  text-align: right;
}

.cancelButton {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 26px;
  height: auto;
  background-color: $primary-text-bold-color;
  cursor: pointer;
  color: $white;
  font-family: FordAntennaCondBold, sans-serif;;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  border-radius: 20px;
  border: solid 1px $primary-text-bold-color;
  letter-spacing: 2.25px;
}

.cancelButton:focus {
  outline: none;
}

.cancelButton:disabled {
  color: gray;
  cursor: auto;
}

.cancelOrderButton {
  @extend .cancelButton;
  background-color: white;
  color: $primary-text-bold-color;
}