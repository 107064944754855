@import "../../../../styles/empCssLibs/variables";

.labelError {
  font-family: FordAntennaCondMedium, sans-serif;
  font-weight: 500;
  line-height: 2.08;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
  font-size: 20px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.vehicleInfoGrid {
  padding: 0 4% 15px 4%;
}

.bottomBorder {
  border-bottom: 1px solid $primary-text-bold-color;

}

.infoDetails {
  font-size: 12px;
  font-family: FordAntenna-Regular, sans-serif;
  letter-spacing: 1.5px;
  align-items: flex-start;
  display: flex;
  padding-bottom: 10px;
  color: $primary-text-color;
}

.lightHeader {
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  color: $secondary-text-color;
  text-transform: uppercase;
  padding: 20px 0;
}

.lightHeader div {
  padding-left: 3%
}

.darkHeader {
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  color: $secondary-text-color;
  text-transform: uppercase;
  background: $smoky-gray;
  padding: 20px 0;
}

.darkHeader div {
  padding-left: 3%;
}

.headerBar {
  width: 100%;
  font-size: 14px;
  font-family: FordAntennaCondMedium, sans-serif;
  letter-spacing: 2.25px;
  padding: 0 0 0 2.5%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-bold-color;
}

.specsContainer {
  padding-top: 2%;
}

.textRightAlign div {
  text-align: right;
}

.darkIndentDetails {
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  color: $secondary-text-color;
  line-height: 1.3;
  text-transform: uppercase;
  background: $smoky-gray;
  padding: 10px 0;
}

.darkIndentDetails div {
  padding-left: 6%;
}

.priceValue {
  text-transform: none;
  text-align: right;
  padding-right: 10%;
}

.newHeader {
  @extend .lightHeader;
  color: $primary-text-bold-color;
  font-weight: bold;
  font-family: FordAntennaCond-Medium, sans-serif;
}


.lightIndentDetails {
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  color: $primary-text-color;
  line-height: 2.92px;
  text-transform: uppercase;
  padding: 15px 0;
}

.lightIndentDetails div {
  padding-left: 6%;
}

.lightDoubleIndentDetails {
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  color: $primary-text-color;
  line-height: 2.92px;
  text-transform: uppercase;
  padding: 15px 0;

  div {
    padding-left: 9%;
  }
}

.handleLongDescText {
  @extend .lightDoubleIndentDetails;
  line-height: 2;
  padding: 12px 0;
}

.darkDoubleIndentDetails {
  font-family: FordAntennaCond-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  color: $secondary-text-color;
  line-height: 2.92px;
  text-transform: uppercase;
  background: $smoky-gray;
  padding: 15px 0;
}

.darkDoubleIndentDetails div {
  padding-left: 9%;
}