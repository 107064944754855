.infoIcon {
    position: static;
    background-color: #4a90e2;
    padding: 3px 8px;
    border-radius: 25px;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    max-height: 13px;
    left: -35px;
    margin-right: 10px;
    margin-top: 14px;
}

.header {
    display: flex;
}

.header p {
    font-family: FordAntenna-Regular, sans-serif;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 1px;
    color: #4a4a4a;
}
