@import "../../../styles/empCssLibs/variables";

.infoBar {
  padding-left: 6%;
  padding-right: 6%;
  text-align: left;
}

.iconWrapper {
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.padded {
  padding-left: 5px;
}

.pricing {
  font-family: FordAntennaCondExtraLight, sans-serif;
  font-size: 40px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: 5px;
  color: $primary-text-bold-color;
  margin: 30px 0px;
  padding: 0 6%;
}

.varMarketing {
  font-family: FordAntennaCond, sans-serif;
  font-size: 11px;
  color: #2861a4;
  text-align: center;
  background: #eef5ff;
  padding: 5px 0px;
  font-weight: bold;
  line-height: 1.45;
  letter-spacing: 1.55px;
}

.numChecked {
  color: #4a90e2;
  padding-right: 5px;
  font-size: 20px;
}

.modelDiv {
  text-align: left;
  margin-left: -0.1%;
}

.modelFormControl {
  width: 100px;
}

.dealerName {
  padding: 5px 10px;
  margin: 0;
}

.unonboardedDealer {
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
}

.accessoriesHeader {
  padding: 30px 6%;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 1px;
  color: $primary-text-color;
}

.dropdownContainer {
  display: flex;
}

.dropdownItem {
  height: 100px;
  margin: 5px;
}

.modelDropdownDiv {

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dropDownText {
  padding: 5px 10px;
  margin: 0;
}

.subTitle {
  padding: 10px 0 5px 0;
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 12px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
  font-family: FordAntennaCondMedium, sans-serif;
}

.priceType {
  height: 200px;
  box-shadow: 0 0 5px 2px rgba(74, 74, 74, 0.05);
  border: solid 1px #b8bec5;
  object-fit: contain;
  display: flex;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  background: linear-gradient(to bottom, $light-blue, $primary-text-bold-color);
  color: $primary-bg-color;
  text-decoration: none;
  margin-bottom: 40px;
}

.priceTitle {
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 20px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2.5px;
  padding-top: 12px;
  color: $primary-bg-color;
  text-transform: uppercase;
  text-align: left;
}

.pricingText {
  height: 110px;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: #ffffff;
  padding-top: 25px;
  text-align: left;
}

.pageBottom {
  height: 110px;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.1px;
  color: #ffffff;
  text-align: start;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

.offersWrapper {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  color: #979797;
  text-align: center;
  background: #f5f9ff;
  padding: 5px 0px;
}

.rightChevron {
  height: 18px;
  width: 18px;
  color: #ffffff;
  margin-bottom: -7px;
}

.iballRight {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.toggleAllCheckboxDiv {
  text-align: right;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toggleAllCheckboxDiv > div {
  margin-left: 5px;
}

.editButtonLink {
  text-align: right;
  padding-top: 5%;
}

.applied {
  font-family: FordAntennaCond-Med, sans-serif;
  font-size: 20px;
  color: #00e600;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.subHeader {
  text-align: left;
  font-size: 12px;
  color: $tundora-light;
}

button.confirmButton {
  border: none;
  color: #3f51b5;
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;
}

button.confirmButton:hover {
  background: none;
  text-decoration: underline;
}

.appliedPricing {
  text-align: center;
  justify-content: flex-end;
}

.subNav {
  text-align: center;
}

.notFound {
  padding-left: 6%;
  padding-top: 1%;
  text-align: left;
}

.dealerTrans {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 1px;
  margin: 10px 0px;
  color: $primary-text-color;
}

.equation {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  color: $primary-text-color;
}

.minus {
  color: $warning-text-color;
}

.plus {
  color: $light-green;
}

.equal {
  color: $soft-blue;
}

.navButtonDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.titleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}

.navButtonDiv a {
  width: 400px;
  padding: 20px;
  text-align: center;
  background: linear-gradient(to bottom, $light-blue, $primary-text-bold-color);
  color: $primary-bg-color;
  text-decoration: none;
  margin-bottom: 40px;
}

.navButtonDiv a span {
  font-size: 18px;
  font-family: FordAntennaCondMedium, sans-serif;
}

button.applyBtn {
  width: 340px;
  height: 48px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #00ad46, #00913b);
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.actionSection {
  height: 26px;
  text-align: center;
  margin-top: 50px;
}

.pageMarginTop {
  margin-top: 5%;
}

.dealerFeesDiv{
  padding-top: 20PX;
}

.warningMessage{
  color: $warning-text-color;
}
.exportAllDiv{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.exportAllDiv :hover{
  cursor: pointer;
}
.exportAll{
  letter-spacing: 1.5px;
  font-size: 12px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
  font-family: FordAntennaCondMedium, sans-serif;
}