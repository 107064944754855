@import '../../../styles/empCssLibs/variables';

.subHeader {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 1.25px;
  color: $primary-text-color;
  padding: 15px 6%;
}

.prompt {
  color: $primary-text-bold-color;
  font-size: 12px;
  letter-spacing: 1.25px;
  font-family: FordAntennaCond, sans-serif;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.secondaryPrompt {
  font-size: 12px;
  color: $primary-text-color;
  font-family: FordAntenna-Regular, sans-serif;
}

.panel {
  color: $secondary-text-color;
  background-color: $navigation-bg-color;
  padding: 15px 6%;
  align-items: center;
  font-size: 12pt;
  letter-spacing: 2.25px;
  border-bottom: solid 1px $navigation-bg-color;
  border-top: 1px solid $navigation-bg-color;
}

.radioContainer {
  width: 100%
}

.labels {
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 10px;
  letter-spacing: 2.8px;
  color: $primary-text-bold-color;
  padding-left: 5px;
  text-transform: uppercase;
  font-weight: bold;
}

button.applyBtn {
  width: 340px;
  height: 48px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #00ad46, #00913b);
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

button.disableButton {
  @extend .applyBtn;
  background-image: none;
  background-color: gray;
  color: $white;
}

.actionSection {
  height: 26px;
  text-align: center;
  margin-top: 50px;
}

.aldStatusDropdown {
  height: 75px;
  display: flex;
  justify-content: flex-end;
  margin-right: 54px;
}

.cupidIdWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.cupidIdLabel {
  font-family: FordAntennaCond, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  text-align: right;
  color: $tundora;
}

.cupidIdValue {
  @extend .cupidIdLabel;
  font-weight: bold;
}

.viewOnly {
  color: $primary-text-bold-color;
  font-size: 14px;
  letter-spacing: 1.25px;
  font-family: FordAntennaCond, sans-serif;
  text-transform: uppercase;
  padding-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
