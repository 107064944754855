@import '../../../../../styles/empCssLibs/variables';

.tableRowHeader, .tableRowContent {
  border-bottom: 0.5px solid $secondary-border-color;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
}

.tableRowHeader {
  margin-top: 46px;
  border-bottom: 5px solid $primary-text-bold-color;
}

.tableRowContent {
  padding-top: 5px;
  padding-bottom: 5px;
}

.reservationInputGridModel {
  height: 45px;
  display: flex;
  align-items: center;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.01px;
}

.reservationInputGridRightCell {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.2;
  letter-spacing: 2.01px;
}

.validatorCell {
  min-height: 45px;
  font-size: 12px;
}

*::-ms-backdrop, .reservationInputGridRightCell {
  margin-top: 2px;
  padding-top: 4px;
  padding-bottom: 0;
}

.reservationInputGridRightCell input {
  height: 32px;
  text-align: right;
  letter-spacing: 1.25px;
  font-size: 14px;
  font-weight: normal;
  font-family: FordAntennaRegular, sans-serif;
  color: $secondary-text-color;
  background-color: $primary-bg-color;
  padding: 0 14px;
}

.reservationInputGridRightCell input::-ms-clear {
  display: none;
}

*::-ms-backdrop, .reservationInputGridRightCell input {
  height: 27px;
}

.rowCellLeft {
  min-height: 45px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: left;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  color: $secondary-text-color;
  margin-left: 6%;
  position: absolute;
}

.rowCellRight {
  text-align: right;
  font-family: FordAntennaBold, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  margin-right: 6%;
}

.Edit {
  position: relative;
  width: 18px;
  height: 18px;
  display: inline-block;
  padding-left: 12px;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}

.RectangleStart {
  width: 5px;
  height: 5px;
  transform: rotate(-180deg);
  background-color: $button-primary-bg-color;
  position: absolute;
  left: 7px;
  bottom: 0;
  cursor: pointer;
}

.RectangleEnd {
  position: absolute;
  width: 6px;
  height: 15.8px;
  transform: rotate(-315deg);
  border: solid 1px $primary-text-bold-color;
  background-color: $primary-bg-color;
  cursor: pointer;
}

.actionFooter {
  justify-content: flex-end;
}

.noFlex {
  flex-wrap: nowrap;
}

.selectedRow {
  flex-wrap: nowrap;
  background-color: rgba(225, 37, 70, 0.1);
}

.iconContainer {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.icon {
  color: $errorRed;
}


.inputField {
  align-items: flex-end;
}

.inputField div {
  width: 290px;
}

.inputField p {
  text-align: right;
  margin: 5px 0 0 0;
}


.amountColumn {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.5;
  letter-spacing: 1px;
  text-align: right;
  color: $tundora;
  display: contents;
}
