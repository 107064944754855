@import "../../../../styles/empCssLibs/variables";

.offlinePadding {
  padding: 1% 5%;
}

.infoDiv {
  padding: 15px 4%;
  display: flex;
  flex-direction: column;
}

.detailsDiv {
  padding: 7px 37px;
}

.subTitleText {
  color: $primary-text-color;
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.24;
  letter-spacing: 1.5px;
}

.dataText {
  @extend .subTitleText;
  font-family: FordAntennaCondRegular, sans-serif;
  font-weight: normal;
}

.recordedBy {
  @extend .subTitleText;
  padding-left: 25px;
}