@import "src/styles/empCssLibs/variables";

.bottomPadding {
  padding-bottom: 20px;
}

.indent {
  padding: 0 6%;
}

.subheader {
  font-family: FordAntennaRegular, sans-serif;
  margin-top: 14px;
  color: $primary-text-color;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
}

.dealerSearchSubHeader {
  @extend .subheader;
  padding-left: 10px;
}

.titleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}

.subNav {
  height: 50px;
  width: 100%;
  background-image: linear-gradient(to bottom, #164069, $primary-text-bold-color);
}

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $button-primary-bg-color;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 3px;
}


.searchWrapper button {
  border: none;
}

.searchWrapper button:focus {
  outline: none;
}

.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.inputField {
  margin: 10px 0 10px 10px;
  padding: 5px;
  width: 230px;
  height: 30px;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.35);
  background-color: #f6f6f6;
}

.form input:focus {
  outline: none;
}

.form input::placeholder {
  font-size: 14px;
  font-family: FordAntennaCondMed, sans-serif;
}

.form input[type="text"] {
  font-size: 14px;
  font-family: FordAntennaCondMed, sans-serif;
  letter-spacing: 0.71px;
  color: $primary-text-bold-color;
  padding: 5px;
  text-align: left;
}

.noOrders {
  color: $primary-text-color;
  margin: 30px 0;
  text-align: center;
}

.searchIcon {
  width: 32px;
  height: 32px;
  background-image: url("../../../../imgs/search-dark-blue.svg");
  cursor: pointer
}

.searchIcon:hover {
  cursor: pointer;
}

.searchButton {
  background: transparent;
}