@import "../../../../styles/empCssLibs/variables";

.exitButton {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.header {
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
  margin-bottom: 10px;
}

.bodyContent {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  margin-bottom: 15px;

}