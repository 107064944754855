@import "../../../styles/empCssLibs/variables";

.label {
  padding: 10px 0 5px;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 1.5px;
  color: $tundora;
}

.value {
  padding: 10px 0 5px;
  @extend .label;
  font-family: FordAntenna-Regular, sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.gridItem {
  display: flex;
  flex-direction: column;
  padding: 0 0 10px;
  font-weight: 600;
}

.valuationInfoWrapper {
  display: flex;
  margin-left: 34px;
}

.valuationAcceptedDate {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  padding: 23px 21px 0 0;
  color: $tundora;
}

.valuationInfo {
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px;
}

.valuationInfoRow {
  display: flex;
  align-items: center;
}

.valuationInfoLabel {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: $tundora;
}

.valuationInfoValue {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: $tundora;
  padding: 2px 0 2px;
}

.checkBox {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  color: $primary-text-color;
  font-weight: 500;
  padding: 3px 12px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.checkBoxPadding {
  padding: 25px 0 10px;
}

.tradeInWrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 4%;
}

.subHeader {
  padding: 16px 0 18px;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
}
