@import "../../../styles/empCssLibs/variables";

.subHeader {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 1px;
  color: #4a4a4a;
}

.icon {
  cursor: pointer;
}

.amountColumn {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: right;
  color: $tundora;
  display: contents;
}
.inputField {
  align-items: flex-end;
}

.inputField div {
  width: 290px;
}

.inputField p {
  text-align: right;
  margin: 5px 0 0 0;
}

.saved {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 30px 0;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  color: $faux-green;
}

.applyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 30px 0;
}


.applyButton button {
  width: 300px;
  height: 50px;
  border-radius: 0;
  background-image: linear-gradient(to bottom, $light-green, $dark-green);
  border: none;
  cursor: pointer;
  color: white;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
}

.applyButton button:disabled {
  background-image: none;
  background-color: $gray90;
  color: $dusty-gray;
}

.tableRowHeader, .tableRowContent {
  border-bottom: 0.5px solid $secondary-border-color;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
}

.tableRowHeader {
  margin-top: 46px;
  border-bottom: 5px solid $primary-text-bold-color;
}

.tableRowContent {
  background-color: $table-row-bg-color;
  padding-top: 5px;
  padding-bottom: 5px;
}

.thresholdInputGridLeftCell {
  height: 100%;
  display: flex;
  text-align: left;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.2;
  letter-spacing: 2.01px;
  justify-content: flex-start;
}

.thresholdInputGridRightCell {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.2;
  letter-spacing: 2.01px;
}

.validatorCell {
  min-height: 45px;
  font-size: 12px;
}

*::-ms-backdrop, .thresholdInputGridRightCell {
  margin-top: 2px;
  padding-top: 4px;
  padding-bottom: 0;
}

.thresholdInputGridRightCell input {
  height: 32px;
  text-align: right;
  letter-spacing: 1.25px;
  font-size: 14px;
  font-weight: normal;
  font-family: FordAntennaRegular, sans-serif;
  color: $secondary-text-color;
  background-color: $primary-bg-color;
  padding: 0 14px;
}

.thresholdInputGridRightCell input::-ms-clear {
  display: none;
}

*::-ms-backdrop, .thresholdInputGridRightCell input {
  height: 27px;
}

.rowCellLeft {
  min-height: 45px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: left;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  color: $secondary-text-color;
  position: absolute;
  justify-content: flex-start;
}