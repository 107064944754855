@import "../../styles/empCssLibs/variables";
@import "~react-datepicker/src/stylesheets/datepicker.scss";

.datePickerDialogStyles {
  .container {
    padding: 4% 3% 4% 6%;
  }

  .dialogContainer {
    width: 500px;
    height: 100%;
    padding: 16px;
    background: white;
    border-radius: 3px;
  }

  .displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .monthAndYear {
    font-family: FordAntennaCondRegular, sans-serif;
    color: $blue-zodiac;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .buttonsDiv {
    @extend .displayFlex;
    width: 485px;
    padding-top: 15px;
  }

  .buttonText {
    font-family: FordAntennaCondRegular, sans-serif;
    color: $blue-zodiac;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    padding-left: 5px;
  }

  .setButtons {
    @extend .displayFlex;
  }

  .setButtons:hover {
    cursor: pointer;
  }

  .icon {
    content: ' ';
    display: inline-block;
    background: url('../../imgs/chevron-left.svg') no-repeat 3% center;
    width: 18px;
    background-size: 18px 18px;
    border: 0px;
    height: 18px;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: transparent;
    @extend .icon;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: transparent;
  }

  .react-datepicker__portal .react-datepicker__navigation--next {
    @extend .icon;
    border-left-color: transparent;
    transform: rotate(180deg);
  }

  .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: transparent;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 0px;
  }

  .react-datepicker__day-name {
    width: 4rem !important;
    font-family: FordAntennaCondRegular, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: $dusty-gray-light;
    text-transform: uppercase;
    padding-top: 20px;
    line-height: 30px !important;
    letter-spacing: 1.88px;
  }

  .react-datepicker__current-month {
    font-family: FordAntennaCondRegular, sans-serif;
    padding-top: 4px;
    color: $blue-zodiac;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px !important;
    letter-spacing: 1px;
  }

  .react-datepicker__day {
    width: 4rem !important;
    font-family: FordAntennaCondRegular, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: $blue-zodiac;
    letter-spacing: 1.88px;
    line-height: 25px !important;
    margin-top: 9px;
  }

  .selectedDate {
    color: white;
    background-color: $blue-zodiac;
    border-radius: 3px;
  }

  .react-datepicker__day--selected {
    @extend .selectedDate
  }

  .react-datepicker__day--selected:hover {
    @extend .selectedDate
  }

  .react-datepicker__day--keyboard-selected {
    @extend .selectedDate
  }

  .react-datepicker__day--keyboard-selected:hover {
    @extend .selectedDate
  }

  .react-datepicker__day--outside-month {
    color: $dusty-gray;
    background-color: $background-gray;
    border-radius: 3px;
  }

  .headerContainer {
    display: flex;
    align-items: center;
  }

  .closeIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .calendarContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .dialogTitle {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 2.5px;
    color: #092a53;
    font-family: FordAntennaCond, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }

  .dialogTitleLight {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 2.5px;
    color: #092a53;
    font-family: FordAntennaCond, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }


  .dialogDescription {
    font-family: FordAntenna-Regular, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.5px;
    color: $tundora;
    line-height: 18px;
    margin: 15px 40px;
  }

  .cancel {
    padding: 10px 5px 0px 10px;
    background: transparent;
    justify-content: flex-end;
    display: flex;
  }

  .cancel button {
    border: none;
    background: transparent;
    cursor: pointer;
    font-weight: normal;
    font-weight: normal;
  }


  button.applySave {
    width: 385px;
    height: 50px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $button-primary-bg-color;
    font-family: FordAntennaCondMedium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1.5px;
    text-align: center;
    color: white;
  }

  button.applySave:hover {
    background-color: $button-primary-bg-color;
    color: white;
  }


  .calendarContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
}
