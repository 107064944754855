@import "../../../../../styles/empCssLibs/variables";

.root {
  flexGrow: 1;
  box-shadow: none;
}

.rowItem {
  font-family: FordAntennaCondMed, sans-serif;
  color: #4d4d4d;
  letter-spacing: 1px;
  display: flex;
}

.rowBorder {
  border-bottom: 1px solid grey;
  text-align: left;
}

.to {
  padding-right: 25px;
  padding-top: 20px;
  font-size: 14px;
  font-family: FordAntenna-Regular, sans-serif;
}

.delete {
  font-family: FordAntennaCondBold, sans-serif;
  color: $primary-text-bold-color;
  align-items: center;
  justify-content: flex-end;
}

.rowBorder input {
  max-width: 80px;
}

button.deleteButton {
  font-family: FordAntennaCondBold, sans-serif;
  letter-spacing: 1.5px;
  font-size: 12px;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.rangeText {
  font-family: FordAntennaCondMed, sans-serif;
  color: #4d4d4d;
}

.rowBorder input[type="text"] {
  padding: 8.5px 14px;
  color: $primary-text-color;
}

.textBox {
  border: solid 1px $secondary-border-color;
  background-color: $white;
  width: 84px;
  height: auto;
}

.textBox input {
  height: 25px;
  background-color: white;
  box-shadow: inset 2px 2px 2px 0 rgba(0, 0, 0, 0.27);
}
