@import '../../styles/empCssLibs/variables.scss';

.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogContainer {
  width: 100%;
  padding: 2% 0;
}

.dialogActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 40px 0;
}

.checkbox {
  margin: 20px 0;
}

.toggleAllCheckboxDiv {
  text-align: right;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.warning {
  height: 35px;
  width: 60px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/warning.svg');
}

.dialogTitle {
  font-family: FordAntennaCond-Bold, sans-serif;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: 2.5px;
  color: $bright-red;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.dialogDescription {
  flex-shrink: 0;
  margin-bottom: 20px;
  padding: 5px 0 0 12px;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: #575757;
}

.dialogDescriptionBold {
  font-weight: bold;
}

.dialogDescription ul {
  padding: 0;
}

.dialogDescription li {
  list-style-position: outside;
  margin-left: 1em;
  padding: 8px;
}

button.confirm {
  width: 340px;
  height: 48px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $primary-text-bold-color;
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

button.confirm:hover {
  background-color: $primary-text-bold-color;
}

button.confirm:disabled {
  @extend .confirm;
  background-color: darkgray;
  color: dimgrey;
}

.cancel {
  padding: 10px 5px 0px 10px;
  background: transparent;
  justify-content: flex-end;
  display: flex;
}

.cancel button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

button.disableButton {
  background-color: gray;
  color: $white;
}

button.confirmButton {
  width: 100%;
  max-width: 400px;
  min-height: 48px;
  height: auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  border: none;
  border-radius: 0;
  margin: 20px 0;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 1.5px;
}

button.confirmButton:disabled {
  background-color: gray;
  color: $white;
}

button.confirmButton:hover {
  background-color: $button-primary-bg-color;
}

button.cancelButton {
  width: 48%;
  box-shadow: 0 1px 4px 0 rgba(74, 74, 74, 0.5);
  height: 50px;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
  color: $primary-text-bold-color;
  border-radius: 0;
}

button.goBack {
  width: 48%;
  height: 50px;
  box-shadow: 0 1px 4px 0 rgba(74, 74, 74, 0.5);
  background-color: white;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
  color: $primary-text-bold-color;
}

button.goBack:hover {
  background: white;
}

button.confirmShared {
  width: 48%;
  height: 50px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $button-primary-bg-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.5px;
  text-align: center;
  color: white;
}

button.confirmShared:hover {
  background-color: $button-primary-bg-color;
  color: white;
}

button.confirmShared:disabled {
  color: $dusty-gray;
  box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.5);
  background-color: $gray90;
}

