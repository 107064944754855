@import "../../styles/empCssLibs/variables";

.backButtonDiv {
  padding-top: 50px;
  display: flex;
  justify-content: flex-start;
}

.backButton {
  display: flex;
  align-items: center;
  text-transform: initial;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: $blue-zodiac;
  letter-spacing: 1.98px;
  text-decoration: none;

  .arrowLeft {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid $blue-zodiac;
    margin-right: 10px;
  }
}

