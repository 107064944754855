@import '../../../../styles/empCssLibs/variables';


.businessSettings {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  color: $tundora-light;
  padding-top: 24px;
  padding-bottom: 36px;
}

.headerBackGround {
  height: 60px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.subSectionBackGround {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.subSectionPriceBackGround {
  height: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.parent {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskHeader {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.65px;
  //line-height: 2;
  color: $blue-zodiac;
}

.tableBottom {
  padding-bottom: 31px;
}

.taskDetails {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.98px;
  color: $tundora;
}

.subheader {
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: $tundora-light;
  padding-right: 5%;
  padding-left: 5%;
  margin: 30px 0;
}

.regionDiv {
  margin: 20px 0;
  background: $alabaster;
}

.regionHeader {
  padding: 10px 5%;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: $blue-zodiac;
}

.greyDiv {
  background: $alabaster;
  padding: 10px 0;
  margin: 30px 0;
}

.greyDivHeader {
  padding: 5px 5%;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: $blue-zodiac;
}

.greyDivText {
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: $tundora-light;
  padding: 5px 5%;
}

.rowBorder {
  border-bottom: 0.2px solid #efefef;
  text-align: left;
}

.divider {
  border-bottom: 0.2px solid #efefef;
  padding-top: 23px;
}

.rowHeader {
  color: #979797;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.headerWrap {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.6px;
  color: $blue-zodiac;
}

.rowItem {
  font-family: FordAntenna-Regular, sans-serif;
  padding: 5px 0px;
  color: $tundora-light;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.rowItem input {
  border-radius: 0;
  padding: 2px 10px;
  width: 70px;
  height: 32px;
  font-size: 14px;
  font-family: FordAntennaCondMed, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  text-align: right;
  box-shadow: inset 2px 2px 2px 0 rgba(74, 74, 74, 0.5);
  border: solid 1px #4a4a4a;
  background-color: white;
}

.actionSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

button.saveBtn {
  height: 52px;
  width: 302px;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;

  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #00ad46, #00913b);
}

.pricesApplied {
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 3px;
  text-align: center;
  color: #00ad46;

}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.left {
  justify-content: flex-start;
}

.toggleWrapper {
  box-shadow: 0 1px 0 0 #ffffff, inset 0 2px 5px 0 rgba(0, 0, 0, 0.35), inset 0 -2px 5px 0 #ffffff !important;
}

.subSectionPriceBackGround input {
  border-radius: 0;
  padding: 2px 10px;
  width: 70px;
  height: 32px;
  font-size: 14px;
  font-family: FordAntennaCondMed, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  text-align: right;
  box-shadow: inset 2px 2px 2px 0 rgba(74, 74, 74, 0.5);
  border: solid 1px #4a4a4a;
  background-color: white;
}

.inputField input {
  width: 80px;
}