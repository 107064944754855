@import "../../../../styles/empCssLibs/variables";

.wallBoxInfo {
  padding: 15px 4%;
}


.dialog {
  padding: 6%;
}

.header {
  width: 100%;
  text-align: center;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
}

.subheader {
  width: 100%;
  margin: 15px 4% 30px 4%;
  font-stretch: normal;
  font-style: normal;
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.45;
  letter-spacing: 1.5px;
  text-align: left;
  color: $white-gray;
}

.dialogActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.info {
  padding: 7px 35px;
}

.installationRequestedDate {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 14px;
  color: $primary-text-color;
  font-weight: 600;
  padding: 7px 22px 7px 0;
  letter-spacing: 1.8px;
}

.installationRequestedCaption {
  @extend .installationRequestedDate;
  font-weight: normal;
}


.statusNormal {
  @extend .installationRequestedDate;
  padding: 7px 0 7px 0;
  color: $blue-zodiac;
}

.defaultStatus {
  @extend .installationRequestedDate;
  padding: 7px 0 7px 0;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  opacity: 0.8;
  z-index: 2;
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.3s ease - in -out;
  -moz-transition: all 0.3s ease - in -out;
  -o-transition: all 0.3s ease - in -out;
  -ms-transition: all 0.3s ease - in -out;
  transition: all 0.3s ease - in -out;
}